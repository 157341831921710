import './ViewSession.scss';

import React, {useEffect, useState} from 'react';
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryLabel,
    VictoryLine,
    VictoryScatter,
    VictoryTooltip
} from 'victory';
import moment from "moment";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import {getActiveCareSession} from '../../store/slices/activeCareSessions';
import { useAppDispatch } from "../../store";
import {RootState, useTypedSelector} from "../../store/reducers";

import ActivityIndicator from "../../components/ActivityIndicator";
import H4 from "../../components/H4";
import H5 from "../../components/H5";
import Card from '../../components/Card';
import FormRow from '../../components/FormRow';
import Button from "../../components/Button";

const BAR_COLOR = '#06BEBD';
const TEXT_COLOR = '#242A34';

const ViewSession: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const [initialized, setInitialized] = useState(false);
    const [pressureData, setPressureData] = useState(null);

    const {token} = useTypedSelector((state) => state.auth);

    const { isGettingCareSession, session, sessionData } = useTypedSelector((state) => state.activeCareSessions);

    useEffect(() => {
        const initialize = async () => {
            if(session.id !== parseInt(id)) {
                return navigate('/dashboard');
            }
            try {
                let res = await dispatch(getActiveCareSession({id})).unwrap();
                setUpData(res);
            } catch(err) {
                console.log('ViewSession initialize err', err);
            }
        };

        if(id) {
            initialize();
        }
    }, [id]);

    const generateTimeLabel = (datum, obj) => {
        let val = sessionData[obj].y[datum._x - 1];
        console.log(val);
        const hours = Math.floor(val / 60);
        const minutes = Math.floor((val % 60));
        return `${hours} hour${hours !== 1 ? 's' : ''} and\n${minutes} minute${minutes !== 1 ? 's' : ''}`;
    }

    const generatePercentageLabel = (datum, obj) => {
        let val = sessionData[obj].y[datum._x - 1];
        return `${val}%`;
    }

    const generatePushValueLabel = (datum, obj) => {
        let val = sessionData[obj].y[datum._x - 1];
        return `${val} Pushes`;
    }

    const generatePressureXAxisTicks = () => {
        let ticks = [];

        pressureData.forEach((d) => {
            let dateOnly = d.x.split('T')[0];
            if(!ticks.includes(dateOnly)) {
                ticks.push(dateOnly);
            }
        });

        ticks.forEach((d, i) => {
            ticks[i] = `${d}T17:00:00.000Z`;
        })

        return ticks;
    };

    const setUpData = (res) => {
        console.log('res', res);

        let data = res.pressureChartValues.y.map((v, i) => ({
            x: res.pressureChartValues.x[i],
            y: v
        }));

        setPressureData(data);

        setInitialized(true);
    };

    const BAR_DOMAIN: any = {x: [1, 6], y: [0, 5]};
    const BAR_STYLE = {data: { fill: BAR_COLOR}};
    const BAR_WIDTH = 32;

    const CHART_DOMAIN_PADDING: any = {x: 20, y: [20, 0]};
    const CHART_HEIGHT = 360;
    const CHART_PADDING = {left: 60, right: 20, top: 40, bottom: 40};

    const X_AXIS_TICK_LABEL_COMPONENT = (
        <VictoryLabel style={{
            fill: TEXT_COLOR,
            fontSize: 14,
            textAlign: 'left',
            fontWeight: (d: any) => {
                if(moment(d.stringTicks[d.index]).format('MMM DD') === moment().format('MMM DD')) {
                    return 'bold';
                } else {
                    return 'normal';
                }
            }}
        } />
    );
    const X_AXIS_STYLE = {
        axis: {stroke: '#cccccc'},
        tickLabels: {fill: TEXT_COLOR, fontSize: 16, textAlign: 'left'},
        grid: {stroke: 'none'},
    };
    const X_AXIS_TICK_FORMAT = (t) => moment(t).format('ddd[\n]MMM DD');

    const Y_AXIS_STYLE = {
        axis: {stroke: '#cccccc'},
        axisLabel: {fill: TEXT_COLOR, angle: 0, padding: 40, fontSize: 16},
        tickLabels: {fill: TEXT_COLOR, fontSize: 14},
        grid: {stroke: '#cccccc'}
    };

    const lastVisit = session?.currentClinicVisit ? session.currentClinicVisit : (session?.clinicVisits ? session.clinicVisits[session.clinicVisits.length - 1] : {});

    let walkingMax = 1;

    sessionData.walkingChartValues?.convertedY?.forEach((y) => {
        if(y > walkingMax) {
            walkingMax = y;
        }
    })

    let legUpMax = 1;

    sessionData?.legUpChartValues?.convertedY?.forEach((y) => {
        if(y > legUpMax) {
            legUpMax = y;
        }
    })

    let legDownMax = 1;

    sessionData?.legDownChartValues?.convertedY?.forEach((y) => {
        if(y > legDownMax) {
            legDownMax = y;
        }
    })

    let maxNumPushes = 1;

    sessionData?.numPushesChartValues?.convertedY?.forEach((y) => {
        if(y > legDownMax) {
            maxNumPushes = y;
        }
    });

    return (
        <div className="page-content">
            <H4>
                Tag ID {lastVisit.sensorTag?.macAddress}
            </H4>

            <Button href={process.env.REACT_APP_API_URL + `/admin/v1/export/csv?care_session_id=${id}&t=${token}`}>
                Download Data CSV
            </Button>

            {isGettingCareSession || !initialized ? (
                <ActivityIndicator />
            ) : (
                <div className="charts">
                    <Card className="chart-container">
                        <H5>
                            WALKING History<br/>
                            Since last clinic visit
                            <div className="chart-container__date">
                                On {moment(lastVisit.startAt).format('dddd MMM DD h:mm a')}
                            </div>
                        </H5>

                        <VictoryChart
                            domainPadding={CHART_DOMAIN_PADDING}
                            height={CHART_HEIGHT}
                            padding={CHART_PADDING}
                        >
                            <VictoryAxis
                                tickValues={sessionData.walkingChartValues.x}
                                style={X_AXIS_STYLE}
                                tickCount={sessionData.walkingChartValues.x.length}
                                tickFormat={X_AXIS_TICK_FORMAT}
                                tickLabelComponent={X_AXIS_TICK_LABEL_COMPONENT}
                            />
                            <VictoryAxis
                                crossAxis
                                dependentAxis
                                style={Y_AXIS_STYLE}
                                orientation="left"
                                tickCount={5}
                                tickFormat={(t) => `${t} hrs`}
                            />
                            <VictoryBar
                                barWidth={BAR_WIDTH}
                                data={[0, ...sessionData.walkingChartValues.convertedY]}
                                domain={{...BAR_DOMAIN, y: [0, walkingMax]}}
                                labelComponent={<VictoryTooltip/>}
                                labels={({datum}) => generateTimeLabel(datum, 'walkingChartValues')}
                                style={BAR_STYLE}
                            />
                        </VictoryChart>
                    </Card>

                    <Card className="chart-container">
                        <H5>
                            LEG UP History<br/>
                            Since last clinic visit
                            <div className="chart-container__date">
                                On {moment(lastVisit.startAt).format('dddd MMM DD h:mm a')}
                            </div>
                        </H5>

                        <VictoryChart
                            domainPadding={CHART_DOMAIN_PADDING}
                            height={CHART_HEIGHT}
                            padding={CHART_PADDING}
                        >
                            <VictoryAxis
                                tickValues={sessionData.legUpChartValues.x}
                                style={X_AXIS_STYLE}
                                tickCount={sessionData.legUpChartValues.x.length}
                                tickFormat={X_AXIS_TICK_FORMAT}
                                tickLabelComponent={X_AXIS_TICK_LABEL_COMPONENT}
                            />
                            <VictoryAxis
                                crossAxis
                                dependentAxis
                                style={Y_AXIS_STYLE}
                                orientation="left"
                                tickCount={5}
                                tickFormat={(t) => `${t} hrs`}
                            />
                            <VictoryBar
                                barWidth={BAR_WIDTH}
                                data={[0, ...sessionData.legUpChartValues.convertedY]}
                                domain={{...BAR_DOMAIN, y: [0, legUpMax]}}
                                labelComponent={<VictoryTooltip/>}
                                labels={({datum}) => generateTimeLabel(datum, 'legUpChartValues')}
                                style={BAR_STYLE}
                            />
                        </VictoryChart>
                    </Card>

                    <Card className="chart-container">
                        <H5>
                            LEG DOWN History<br/>
                            Since last clinic visit
                            <div className="chart-container__date">
                                On {moment(lastVisit.startAt).format('dddd MMM DD h:mm a')}
                            </div>
                        </H5>

                        <VictoryChart
                            domainPadding={CHART_DOMAIN_PADDING}
                            height={CHART_HEIGHT}
                            padding={CHART_PADDING}
                        >
                            <VictoryAxis
                                tickValues={sessionData.legDownChartValues.x}
                                style={X_AXIS_STYLE}
                                tickCount={sessionData.legDownChartValues.x.length}
                                tickFormat={X_AXIS_TICK_FORMAT}
                                tickLabelComponent={X_AXIS_TICK_LABEL_COMPONENT}
                            />
                            <VictoryAxis
                                crossAxis
                                dependentAxis
                                style={Y_AXIS_STYLE}
                                orientation="left"
                                tickCount={5}
                                tickFormat={(t) => `${t} hrs`}
                            />
                            <VictoryBar
                                barWidth={BAR_WIDTH}
                                data={[0, ...sessionData.legDownChartValues.convertedY]}
                                domain={{...BAR_DOMAIN, y: [0, legDownMax]}}
                                labelComponent={<VictoryTooltip/>}
                                labels={({datum}) => generateTimeLabel(datum, 'legDownChartValues')}
                                style={BAR_STYLE}
                            />
                        </VictoryChart>
                    </Card>

                    <Card className="chart-container">
                        <H5>
                            Compression Wrap % Pressure<br />
                            Since last clinic visit
                            <div className="chart-container__date">
                                On {moment(lastVisit.startAt).format('dddd MMM DD h:mm a')}
                            </div>
                        </H5>

                        <VictoryChart
                            domainPadding={{...CHART_DOMAIN_PADDING, x: [20, 0]}}
                            height={CHART_HEIGHT + 20}
                            padding={{...CHART_PADDING, left: 40, right: 30, bottom: 60}}
                        >
                            <VictoryAxis
                                //tickValues={generatePressureXAxisTicks()}
                                tickValues={pressureData.map((d) => d.x)}
                                style={X_AXIS_STYLE}
                                tickCount={5}
                                tickFormat={(t) => moment(t).format('ddd[\n]MMM DD[\n]hh:mma')}
                                tickLabelComponent={<VictoryLabel style={{
                                    fill: TEXT_COLOR,
                                    fontSize: 14,
                                    textAlign: 'left',
                                    fontWeight: (d: any) => {
                                        if(d.stringTicks && moment(d.stringTicks[d.ticks[d.index]]).format('MMM DD') === moment().format('MMM DD')) {
                                            return 'bold';
                                        } else {
                                            return 'normal';
                                        }
                                    }}
                                } />}
                            />
                            <VictoryAxis
                                crossAxis
                                dependentAxis
                                style={Y_AXIS_STYLE}
                                orientation="left"
                                tickCount={3}
                                tickFormat={(t) => `${t}%`}
                                tickValues={[0, 20, 100]}
                                tickLabelComponent={<VictoryLabel style={{
                                    fill: TEXT_COLOR,
                                    fontSize: 14,
                                    textAlign: 'right',
                                    fontWeight: (d: any) => {
                                        if(d.index === 1) {
                                            return 'bold';
                                        } else {
                                            return 'normal';
                                        }
                                    }}
                                } />}
                            />
                            <VictoryScatter
                                data={pressureData}
                                domain={{x: [0, pressureData.length], y: [0, 100]}}
                                style={BAR_STYLE}
                            />
                            <VictoryLine
                                style={{
                                    data: {
                                        stroke: 'blue'
                                    }
                                }}
                                data={pressureData.map(() => 20)}
                                domain={{x: [0, pressureData.length], y: [0, 100]}}
                            />
                        </VictoryChart>
                        {/* Removed 8/24/2022 per Noah's request */}
                        {/*<FormRow>*/}
                        {/*    <div className="chart-container__latest-pressure-value">*/}
                        {/*        Latest Value: {pressureData?.length > 0 && pressureData[pressureData.length - 1].y ? pressureData[pressureData.length - 1].y : 0}%*/}
                        {/*    </div>*/}
                        {/*</FormRow>*/}
                    </Card>

                    <Card className="chart-container">
                        <H5>
                            PUSH NOTIFICATION History<br/>
                            Since last clinic visit
                            <div className="chart-container__date">
                                On {moment(lastVisit.startAt).format('dddd MMM DD h:mm a')}
                            </div>
                        </H5>

                        <VictoryChart
                            domainPadding={CHART_DOMAIN_PADDING}
                            height={CHART_HEIGHT}
                            padding={CHART_PADDING}
                        >
                            <VictoryAxis
                                tickValues={sessionData.numPushesChartValues.x}
                                style={X_AXIS_STYLE}
                                tickCount={sessionData.numPushesChartValues.x.length}
                                tickFormat={X_AXIS_TICK_FORMAT}
                                tickLabelComponent={X_AXIS_TICK_LABEL_COMPONENT}
                            />
                            <VictoryAxis
                                crossAxis
                                dependentAxis
                                label="Push"
                                style={Y_AXIS_STYLE}
                                orientation="left"
                                tickFormat={(t) => t}
                                tickCount={maxNumPushes < 6 ? maxNumPushes : 6 }
                            />
                            <VictoryBar
                                barWidth={BAR_WIDTH}
                                data={[0, ...sessionData.numPushesChartValues.convertedY]}
                                domain={{...BAR_DOMAIN, y: [0, ...sessionData.numPushesChartValues.convertedY]}}
                                labelComponent={<VictoryTooltip/>}
                                labels={({datum}) => generatePushValueLabel(datum, 'numPushesChartValues')}
                                style={BAR_STYLE}
                            />
                        </VictoryChart>
                    </Card>

                    <Card className="chart-container">
                        <H5>
                            DATA COMPLETENESS History<br/>
                            Since last clinic visit
                            <div className="chart-container__date">
                                On {moment(lastVisit.startAt).format('dddd MMM DD h:mm a')}
                            </div>
                        </H5>

                        <VictoryChart
                            domainPadding={CHART_DOMAIN_PADDING}
                            height={CHART_HEIGHT}
                            padding={CHART_PADDING}
                        >
                            <VictoryAxis
                                tickValues={sessionData.dataCompletenessChartValues.x}
                                style={X_AXIS_STYLE}
                                tickCount={sessionData.dataCompletenessChartValues.x.length}
                                tickFormat={X_AXIS_TICK_FORMAT}
                                tickLabelComponent={X_AXIS_TICK_LABEL_COMPONENT}
                            />
                            <VictoryAxis
                                crossAxis
                                dependentAxis
                                style={Y_AXIS_STYLE}
                                orientation="left"
                                tickCount={6}
                                tickFormat={(t) => `${t}%`}
                                tickValues={[0, 20, 40, 60, 80, 100]}
                                tickLabelComponent={<VictoryLabel style={{
                                    fill: TEXT_COLOR,
                                    fontSize: 14,
                                    textAlign: 'right',
                                    fontWeight: (d: any) => {
                                        if(d.index === 5) {
                                            return 'bold';
                                        } else {
                                            return 'normal';
                                        }
                                    }}
                                } />}
                            />
                            <VictoryBar
                                barWidth={BAR_WIDTH}
                                data={[0, ...sessionData.dataCompletenessChartValues.convertedY]}
                                domain={BAR_DOMAIN}
                                labelComponent={<VictoryTooltip/>}
                                labels={({datum}) => generatePercentageLabel(datum, 'dataCompletenessChartValues')}
                                style={BAR_STYLE}
                            />
                        </VictoryChart>
                    </Card>
                </div>
            )}
        </div>
    );
};

export default ViewSession;
